// 物业公司列表
const getPropertyList = `/gateway/hc-neighbour/propertyInfo/getPropertyList`;
// 删除
const delPropertyInfoById = `/gateway/hc-neighbour/propertyInfo/delPropertyInfoById`;
// 新增/编辑
const savePropertyInfo = `/gateway/hc-neighbour/propertyInfo/savePropertyInfo`;
// 编辑手机号
const propertyInfoEdit = `/gateway/hc-neighbour/propertyInfo/propertyInfoEdit`;
// 详情
const getPropertyInfoById = `/gateway/hc-neighbour/propertyInfo/getPropertyInfoById`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
// 删除超话接口
const deleteTopicUrl = `/gateway/hc-neighbour/topic/deleteTopic`;
//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
export {
  getPropertyList,
  delPropertyInfoById,
  savePropertyInfo,
  getPropertyInfoById,
  getBuildListURL,
  addTopicURL,
  updateTopicURL,
  deleteTopicUrl,
  getTopicListURL,
  getTopicInfoByIdURL,
  propertyInfoEdit,
};
